var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:['outset', 'welfare-card', 'mx-16', 'my-10']},[_c('v-card-title',[_vm._v(" ポイント交換申請詳細 "),(_vm.order.orderStatus === _vm.ORDER_STATUS.OPEN)?_c('v-chip',{staticClass:"ml-3",attrs:{"color":"info","label":""}},[_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LABEL[_vm.order.orderStatus])+" ")]):_c('v-chip',{staticClass:"ml-3",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LABEL[_vm.order.orderStatus])+" ")])],1),_c('table',{staticClass:"vertical-table"},[_c('tr',[_c('th',[_vm._v("交換申請日")]),_c('td',[_vm._v(_vm._s(_vm.order.createdAt))])]),_c('tr',[_c('th',[_vm._v("最終更新日")]),_c('td',[_vm._v(_vm._s(_vm.order.updatedAt))])]),_c('tr',[_c('th',[_vm._v("交換申請者")]),_c('td',[_vm._v(_vm._s(_vm.order.user))])]),_c('tr',[_c('th',[_vm._v("管理者備考")]),_c('td',[_c('v-textarea',{attrs:{"outlined":"","no-resize":"","rows":"3","placeholder":"この申請を管理する上での備考メモ","hint":"この申請を管理する上での備考メモにお役立てください","value":_vm.order.note},on:{"input":_vm.inputNote}})],1)])]),_c('v-card-title',[_vm._v(" ポイント交換アイテム詳細 ")]),_c('v-data-table',{staticClass:"pa-10",staticStyle:{"white-space":"pre-wrap"},style:(_vm.styles.app),attrs:{"headers":_vm.itemTableHeaders,"items":_vm.order.items,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.expirationDate",fn:function(ref){
var item = ref.item;
return [(item.expirationDate != 'なし'
          && item.status === _vm.ITEM_STATUS.IN_PROGRESS
          && item.isExpired)?_c('span',{staticClass:"expired-text"},[_vm._v(" "+_vm._s(item.expirationDate)+" ")]):(item.expirationDate)?_c('span',[_vm._v(" "+_vm._s(item.expirationDate)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status === _vm.ITEM_STATUS.IN_PROGRESS)?_c('v-select',{attrs:{"outlined":"","dense":"","value":item.status,"items":_vm.itemStatuses},on:{"change":function($event){return _vm.changeItemStatus(item.uuid, $event)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.ITEM_STATUS_LABEL[item.status])+" ")])]}},{key:"item.adminNote",fn:function(ref){
          var item = ref.item;
return [(item.status === _vm.ITEM_STATUS.IN_PROGRESS)?_c('v-text-field',{attrs:{"outlined":"","dense":"","value":item.adminNote,"placeholder":"キャンセル時の事由など"},on:{"change":function($event){return _vm.changeItemAdminNote(item.uuid, $event)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.getAdminNoteString(item.adminNote))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.updatedBy)),_c('br'),_vm._v(_vm._s(item.updatedAt)+" ")]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"small-outset mr-5 mb-5",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")]),_c('v-btn',{staticClass:"small-outset ml-5",attrs:{"rounded":"","outlined":""},on:{"click":function($event){_vm.$router.push({ name: 'OrderList' }, function () {})}}},[_vm._v(" 一覧に戻る ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card
    :class="['outset', 'welfare-card', 'mx-16', 'my-10']"
  >
    <!-- title -->
    <v-card-title>
      ポイント交換申請詳細
      <v-chip
        v-if="order.orderStatus === ORDER_STATUS.OPEN"
        color="info"
        label
        class="ml-3"
      >
        {{ ORDER_STATUS_LABEL[order.orderStatus] }}
      </v-chip>
      <v-chip
        v-else
        label
        class="ml-3"
      >
        {{ ORDER_STATUS_LABEL[order.orderStatus] }}
      </v-chip>
    </v-card-title>

    <!-- order information -->
    <table class="vertical-table">
      <tr>
        <th>交換申請日</th>
        <td>{{ order.createdAt }}</td>
      </tr>
      <tr>
        <th>最終更新日</th>
        <td>{{ order.updatedAt }}</td>
      </tr>
      <tr>
        <th>交換申請者</th>
        <td>{{ order.user }}</td>
      </tr>
      <tr>
        <th>管理者備考</th>
        <td>
          <v-textarea
            outlined
            no-resize
            rows="3"
            placeholder="この申請を管理する上での備考メモ"
            hint="この申請を管理する上での備考メモにお役立てください"
            :value="order.note"
            @input="inputNote"
          />
        </td>
      </tr>
    </table>

    <!-- item information -->
    <v-card-title>
      ポイント交換アイテム詳細
    </v-card-title>
    <v-data-table
      :headers="itemTableHeaders"
      :items="order.items"
      :style="styles.app"
      style="white-space: pre-wrap;"
      :hide-default-footer="true"
      class="pa-10"
    >
      <template v-slot:item.expirationDate="{ item }">
        <span
          v-if="item.expirationDate != 'なし'
            && item.status === ITEM_STATUS.IN_PROGRESS
            && item.isExpired"
          class="expired-text"
        >
          {{ item.expirationDate }}
        </span>
        <span
          v-else-if="item.expirationDate"
        >
          {{ item.expirationDate }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-select
          v-if="item.status === ITEM_STATUS.IN_PROGRESS"
          outlined
          dense
          :value="item.status"
          :items="itemStatuses"
          @change="changeItemStatus(item.uuid, $event)"
        />
        <span
          v-else
        >
          {{ ITEM_STATUS_LABEL[item.status] }}
        </span>
      </template>
      <template v-slot:item.adminNote="{ item }">
        <v-text-field
          v-if="item.status === ITEM_STATUS.IN_PROGRESS"
          outlined
          dense
          :value="item.adminNote"
          placeholder="キャンセル時の事由など"
          @change="changeItemAdminNote(item.uuid, $event)"
        />
        <span
          v-else
        >
          {{ getAdminNoteString(item.adminNote) }}
        </span>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedBy }}<br>{{ item.updatedAt }}
      </template>
    </v-data-table>

    <!-- buttons -->
    <v-row justify="center">
      <v-btn
        rounded
        class="small-outset mr-5 mb-5"
        color="primary"
        @click="save"
      >
        保存
      </v-btn>
      <v-btn
        rounded
        outlined
        class="small-outset ml-5"
        @click="$router.push({ name: 'OrderList' }, () => {})"
      >
        一覧に戻る
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin'
import { ITEM_STATUS, ITEM_STATUS_LABEL, ORDER_STATUS, ORDER_STATUS_LABEL } from '@/constants'

export default {
  mixins: [
    CommonMixin
  ],

  props: {
    order: {
      type: Object,
      default: () => {}
    },
    itemStatuses: {
      type: Array,
      default: () => []
    },
    itemTableHeaders: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    updateItems: [],
    note: ''
  }),

  computed: {
    ITEM_STATUS () {
      return ITEM_STATUS
    },
    ITEM_STATUS_LABEL () {
      return ITEM_STATUS_LABEL
    },
    ORDER_STATUS () {
      return ORDER_STATUS
    },
    ORDER_STATUS_LABEL () {
      return ORDER_STATUS_LABEL
    }
  },
  methods: {
    getAdminNoteString (adminNote) {
      if (!adminNote) return '-'
      return adminNote
    },
    save () {
      for (const item of this.updateItems) {
        if (item.status === ITEM_STATUS.CANCELED && !item.adminNote) {
          this.$emit('showValidationMessage', 'キャンセルにする場合は備考を入力してください。キャンセルの理由としてユーザーに通達されます。')
          return
        }
      }
      this.$emit('save', this)
    },
    inputNote (val) {
      this.note = val
    },
    changeItemStatus (uuid, val) {
      const currentItem = this.updateItems.find(itm => { return itm.uuid === uuid })
      if (currentItem) {
        currentItem.status = val
      } else {
        this.updateItems.push({ 'uuid': uuid, 'status': val })
      }
    },
    changeItemAdminNote (uuid, val) {
      const currentItem = this.updateItems.find(itm => { return itm.uuid === uuid })
      if (currentItem) {
        currentItem.adminNote = val
      } else {
        this.updateItems.push({ 'uuid': uuid, 'adminNote': val })
      }
    }
  }
}
</script>
